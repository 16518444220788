//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModalEmbed',
  data () {
    return {
      videoId: '7KiyE1FRS28',
      playerVars: {
        autoplay: 1,
        playsinline: 1
      }
    }
  },
  computed: {
    player () {
      return this.$refs.youtubeembed.player
    }
  },
  methods: {
    async playVideo () {
      await this.player.playVideo()
    },
    onClickRegister () {
      this.openTab('https://elearning.set.or.th/', '_blank')
    },
    playing () {
      // console.log('o/ we are watching!!!')
    },
    onClose () {
      this.$bvModal.hide('modal-video')
    }
  }
}
